import { createRouter, createWebHashHistory } from 'vue-router';
// import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    // {
    //     path: '/',
    //     name: 'app',
    //     component: App,
    //     children: [
    //         {
    //             path: '',
    //             name: 'dashboard',
    //             component: () => import('./components/Dashboard.vue')
    //         },
    //         {
    //             path: '/formlayout',
    //             name: 'formlayout',
    //             component: () => import('./components/FormLayoutDemo.vue')
    //         },
    //         {
    //             path: '/input',
    //             name: 'input',
    //             component: () => import('./components/InputDemo.vue')
    //         },
    //         // ... other routes ...
    //     ]
    // },
    // ... other top-level routes ...
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
